const prod = {
  CONSOLE_API_URL: 'https://console-api.ipfindable.io/console/v1',
  FLYER_API_URL: 'https://api.ipfindable.io/v1/ip',
  IP_PUBLIC_API_KEY: 'AIzaSyBocj5bOnFdW6kQ5p2jYP0ti3hDrXwGX7w'
};

const dev = {
  CONSOLE_API_URL: 'http://localhost:4000/console/v1',
  FLYER_API_URL: 'http://localhost:8000/v1/ip',
  IP_PUBLIC_API_KEY: 'AIzaSyBocj5bOnFdW6kQ5p2jYP0ti3hDrXwGX7w'
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    default:
      return prod;
  }
};

export const env = getEnv();
