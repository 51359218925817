import fetch from 'auth/FetchInterceptor';

const RegisterService = {};

RegisterService.registerAccount = function (params) {
  return fetch({
    url: '/register',
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: params.token })
  })
};

export default RegisterService;
