import {
  HomeOutlined,
  SearchOutlined,
  CodeOutlined,
  FileTextOutlined,
  GlobalOutlined,
  BranchesOutlined,
  SettingOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
  {
    className: 'menu-top',
    submenu: [
      {
        path: `${APP_PREFIX_PATH}/dashboard/default`,
        title: 'Dashboard',
        icon: HomeOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/lookup`,
        title: 'Lookup',
        icon: SearchOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/api-settings`,
        title: 'API Settings',
        icon: CodeOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/timeline`,
        title: 'Timeline',
        icon: FieldTimeOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/billing`,
        title: 'Billing',
        icon: FileTextOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/profile`,
        title: 'Profile',
        icon: GlobalOutlined
      }
    ]
  }
];

const HelpNavTree = [
  {
    className: 'menu-top',
    title: 'Help',
    submenu: [
      {
        path: `${APP_PREFIX_PATH}/dashboard/default`,
        title: 'Documentation',
        icon: BranchesOutlined
      },
      {
        path: `${APP_PREFIX_PATH}/dashboard/default`,
        title: 'Support',
        icon: SettingOutlined
      }
    ]
  }
];

const navigationConfig = [...dashBoardNavTree, ...HelpNavTree];

export default navigationConfig;
