export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const SHOW_AUTH_SUCCESS_MESSAGE = 'SHOW_AUTH_SUCCESS_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const HIDE_AUTH_SUCCESS_MESSAGE = 'HIDE_AUTH_SUCCESS_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const AUTH_TOKEN = 'auth_token';
export const PROVIDER = 'provider';
export const USER_EMAIL = 'user_email';
export const UID = 'uid';
export const ACCOUNT_ID = 'account_id';
export const DISPLAY_NAME = 'display_name';
export const PLAN_NAME = 'plan_name';
export const PLAN_STATUS = 'plan_status';
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE';
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const UT = 'ut';