import { env } from './EnvironmentConfig';

export const APP_NAME = 'IPFindable.io';
export const CONSOLE_API_URL = env.CONSOLE_API_URL;
export const FLYER_API_URL = env.FLYER_API_URL;
export const IP_PUBLIC_API_KEY = env.IP_PUBLIC_API_KEY;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
  navType: 'SIDE',
  sideNavTheme: 'SIDE_NAV_DARK',
  navCollapsed: false,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  locale: 'en',
  currentTheme: 'light',
  direction: 'ltr'
};
