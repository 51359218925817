import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  SHOW_AUTH_SUCCESS_MESSAGE,
  HIDE_AUTH_MESSAGE,
  HIDE_AUTH_SUCCESS_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SEND_PASSWORD_RESET_EMAIL
} from '../constants/Auth';

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  };
};

export const sendPasswordResetEmail = (email) => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL,
    payload: email
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  };
};

export const showAuthSuccessMessage = (message) => {
  return {
    type: SHOW_AUTH_SUCCESS_MESSAGE,
    message
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE
  };
};

export const hideAuthSuccessMessage = () => {
  return {
    type: HIDE_AUTH_SUCCESS_MESSAGE
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING
  };
};
