const FirebaseConfig = {
  apiKey: 'AIzaSyAZsa4EncfdO0DuYZEqLzCtirIPwz7kOeU',
  authDomain: 'ipfindable-io.firebaseapp.com',
  projectId: 'ipfindable-io',
  storageBucket: 'ipfindable-io.appspot.com',
  messagingSenderId: '694255129309',
  appId: '1:694255129309:web:be0cd00cae8948e3c90134',
  measurementId: 'G-DSBZX4KFRQ'
};

export default FirebaseConfig;
