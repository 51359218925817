import { auth, googleAuthProvider } from 'auth/FirebaseAuth';

const FirebaseService = {};

FirebaseService.getIdToken = async (refresh =  false) =>
  await auth.currentUser
    .getIdToken(refresh)
    .then((token) => {
      return token
    })
    .catch((err) => err);

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      return user
    })
    .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.sendEmailVerification = async (email, password) =>
  await auth.currentUser
    .sendEmailVerification()
    .then((data) => data)
    .catch((err) => err);

FirebaseService.sendPasswordResetEmail = async (email) => {
  await auth
    .sendPasswordResetEmail(email)
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
};
FirebaseService.signOutRequest = async () =>
  await auth
    .signOut()
    .then((user) => user)
    .catch((err) => err);

export default FirebaseService;
