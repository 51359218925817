import _ from 'lodash';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import firebaseConfig from 'configs/FirebaseConfig';
import { getAnalytics } from 'firebase/analytics';

if (_.includes(['localhost', '127.0.0.1', '192.168.1.2', '192.168.1.3'], window.location.hostname)) {
  delete firebaseConfig.measurementId;
  firebase.initializeApp(firebaseConfig);
} else {
  const app = firebase.initializeApp(firebaseConfig);
  getAnalytics(app);
}

// firebase utils
const auth = firebase.auth();
const currentUser = auth.currentUser;
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { auth, currentUser, googleAuthProvider };
