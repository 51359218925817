import _ from 'lodash';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import history from '../../history';
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  UID,
  USER_EMAIL,
  ACCOUNT_ID,
  DISPLAY_NAME,
  PLAN_NAME,
  PROVIDER,
  SEND_PASSWORD_RESET_EMAIL,
  UT
} from '../constants/Auth';
import {
  showAuthMessage,
  showAuthSuccessMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated
} from '../actions/Auth';
import FirebaseService from 'services/FirebaseService';
import RegisterService from 'services/RegisterService';

const ENTRY_ROUTE = '/auth/login';

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(FirebaseService.signInEmailRequest, email, password);
      if (user.code === 'auth/user-not-found' || user.code === 'auth/wrong-password') {
        yield put(showAuthMessage('Invalid username or password'));
      } else if (user.message) {
        yield put(showAuthMessage(user.message));
      } else if (!_.get(user, 'user.emailVerified')) {
        yield put(showAuthMessage('Pending email verification, Please verify your account by clicking the link in the verification email sent.'));
      } else {
        localStorage.setItem(USER_EMAIL, user.user.email);
        localStorage.setItem(UID, user.user.uid);
        localStorage.setItem(AUTH_TOKEN, yield call(FirebaseService.getIdToken));
        localStorage.setItem(PROVIDER, 'password');
        const register = yield call(RegisterService.registerAccount, { token: yield call(FirebaseService.getIdToken) });
        if (_.get(register, 'new')) {
          // New account registration
          const newToken = yield call(FirebaseService.getIdToken, true);
          localStorage.setItem(AUTH_TOKEN, newToken);
        }
        localStorage.setItem(UT, _.get(register, 'ut'))
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage('error'));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(FirebaseService.signUpEmailRequest, email, password);
      if (user.code === 'auth/email-already-in-use') {
        yield put(showAuthMessage('The email address is already in use'));
      } else if (user.message) {
        yield put(showAuthMessage(user.message));
      } else if (!_.get(user, 'user.emailVerified')) {
        yield call(FirebaseService.sendEmailVerification);
        yield put(showAuthSuccessMessage('Sign up successful, Please verify your account by clicking the link in the verification email sent.'));
        setTimeout(() => {
          history.push(ENTRY_ROUTE);
          window.location.reload();
        }, 5000);
      } else {
        localStorage.setItem(USER_EMAIL, user.user.email);
        localStorage.setItem(UID, user.user.uid);
        localStorage.setItem(AUTH_TOKEN, yield call(FirebaseService.getIdToken));
        localStorage.setItem(PROVIDER, 'password');
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage('error'));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(USER_EMAIL, user.user.email);
        localStorage.setItem(UID, user.user.uid);
        localStorage.setItem(AUTH_TOKEN, yield call(FirebaseService.getIdToken));
        localStorage.setItem(PROVIDER, 'google');
        const register = yield call(RegisterService.registerAccount, { token: yield call(FirebaseService.getIdToken) });
        if (_.get(register, 'new')) {
          // New account registration
          const newToken = yield call(FirebaseService.getIdToken, true);
          localStorage.setItem(AUTH_TOKEN, newToken);
        }
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* sendPasswordResetEmail() {
  yield takeEvery(SEND_PASSWORD_RESET_EMAIL, function* ({ payload }) {
    const { email } = payload;
    try {
      yield call(FirebaseService.sendPasswordResetEmail, email);
      yield put(showAuthSuccessMessage('Password Reset Email Sent.'));
    } catch (error) {
      yield put(showAuthMessage('Password Reset Failed. Try after sometime.'));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(UID);
        localStorage.removeItem(ACCOUNT_ID);
        localStorage.removeItem(DISPLAY_NAME);
        localStorage.removeItem(PLAN_NAME);
        localStorage.removeItem(USER_EMAIL);
        localStorage.removeItem(PROVIDER);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithFBEmail), fork(signUpWithFBEmail), fork(signInWithFBGoogle), fork(signOut), fork(sendPasswordResetEmail)]);
}
