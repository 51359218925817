import React from 'react';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import utils from 'utils';
import Logo from './Logo';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Menu, Grid, Tag } from 'antd';
import { SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { LogoutOutlined } from '@ant-design/icons';
import { signOut } from 'redux/actions/Auth';

const { useBreakpoint } = Grid;

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const UpgradeOption = () => {
  return (
    <div className="p-3">
      <Button type="primary" block>
        Free Upgrade{' '}
        <Tag color="#87d068" className="fr" style={{ marginTop: -4 }}>
          Soon
        </Tag>
      </Button>
    </div>
  );
};

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, onMobileNavToggle, signOut } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  return (
    <>
      <Logo logoType={'dark'} />
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        style={{ borderRight: 0, paddingLeft: 10, paddingTop: 30 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? 'hide-group-title' : ''}>
        {navigationConfig.map((menu) =>
          menu.title ? (
            <Menu.ItemGroup key={'key_' + menu.title} title={menu.title} className={menu.className}>
              {menu.submenu.map((subMenuFirst) => (
                <Menu.Item key={subMenuFirst.title}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{subMenuFirst.title}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ) : (
            menu.submenu.map((subMenuFirst) => (
              <Menu.Item key={subMenuFirst.title} className={menu.className} onClick={subMenuFirst.function}>
                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                <span>{subMenuFirst.title}</span>
                <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
              </Menu.Item>
            ))
          )
        )}
        <Menu.Item key="Logout" className="menu-bottom" onClick={signOut}>
          <LogoutOutlined />
          <span>Logout</span>
        </Menu.Item>
        <UpgradeOption />
      </Menu>
    </>
  );
};

const MenuContent = (props) => {
  return <SideNavContent {...props} />;
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle, signOut })(MenuContent);
