import _ from 'lodash';
import axios from 'axios';
import history from '../history';
import { notification } from 'antd';
import { CONSOLE_API_URL } from 'configs/AppConfig';
import { AUTH_TOKEN, UID } from 'redux/constants/Auth';

const service = axios.create({
  baseURL: CONSOLE_API_URL,
  timeout: 30000
});

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const idToken = localStorage.getItem(AUTH_TOKEN);

    if (idToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = idToken;
    }

    if (!idToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    console.log(error)
    console.log(error.response)
    // Do something with request error here
    notification.error({
      message: 'Error'
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: 'Error'
    };

    error.response = error.response ? error.response : { message: 'Error' };

    if (_.get(error, 'response.data.message')) error.response = error.response.data;
    if (error.response.status === 400) {
      notificationParam.message = error.response.message;
    } else if (error.response.status === 401) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      localStorage.removeItem(UID);
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    } else if (error.response.status === 403) {
      localStorage.removeItem(UID);
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE + `?error=${error.response.message}`);
      // window.location.reload();
    } else if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    } else if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    } else if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    } else {
      notificationParam.message = error.response.message;
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
